import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { contactSection } from "../content/Common"
import InnerHeader from "../components/molecules/InnerHeader"
import ContactSection from "../components/common/ContactSection"
import { faqs, resourcesPage } from "../content/ResourcesPage"
import BrandParagraph from "../components/atoms/BrandParagraph"
import OurResourcesSection from "../components/pages/Resources/OurResourcesSection"
import FaqsSection from "../components/common/FaqsSection"

const Resources = () => {
  const [searchQuery, setSearchQuery] = useState("")

  return (
    <Layout>
      <Seo title="Resources" />

      <InnerHeader
        title={resourcesPage.title}
        description={resourcesPage.description}
        googleReviewText={resourcesPage.googleReviewText}
        isSearchBox={true}
        formDetails={resourcesPage.formDetails}
        setSearchQuery={setSearchQuery}
      />
      {!searchQuery && (
        <div className="cc-section--horizontal-space pt-12 lg:pt-16">
          <BrandParagraph
            text={resourcesPage.pageLongDescription}
            textEC={`text-center`}
          />
        </div>
      )}

      <OurResourcesSection searchQuery={searchQuery} />

      <FaqsSection faqs={faqs} />

      <ContactSection data={contactSection} sectionTitleAlignment={`center`} />
    </Layout>
  )
}

export default Resources
