import React from "react"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"
import { Link } from "gatsby"
import { postTypesSlug } from "../../content/Global"

const ResourceCard = ({ post }) => {
  const imageUrl = ImageUrlRenderer({ img: post.image.path })
  return (
    <Link
      to={postTypesSlug.resourcesSlug + post.slug}
      href={postTypesSlug.resourcesSlug + post.slug}
    >
      <div
        className="group rounded-[12px] bg-cover bg-center h-[278px] flex justify-center items-end cursor-pointer"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className="rounded-bl-[12px] rounded-br-[12px] w-full text-center bg-[#FFFFFF80] text-primary font-ubuntu text-[16px] leading-[1.4em] font-[700] px-2 py-3 group-hover:bg-[#1C5C4F] transition-colors duration-300 ease-in-out">
          {post.title}
        </div>
      </div>
    </Link>
  )
}

export default ResourceCard
