import React, { useState, useEffect } from "react"
import SectionTitle from "../../atoms/SectionTitle"
import ResourceCard from "../../molecules/ResourceCard"
import { resources } from "../../../content/Resources"

const uniqueCategories = [...new Set(resources.map(post => post.category))]
const POSTS_PER_PAGE = 12

const OurResourcesSection = ({ searchQuery }) => {
  const [selectedCategory, setSelectedCategory] = useState("Housing Seekers")
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [selectedCategory])

  var filteredPosts = resources.filter(post =>
    selectedCategory ? post.category === selectedCategory : true
  )

  filteredPosts = searchQuery
    ? filteredPosts.filter(post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : filteredPosts

  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE)

  const paginatedPosts = filteredPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = pageNumber => setCurrentPage(pageNumber)
  const handlePreviousPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1)
  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1)

  return (
    <div className="bg-primary-light mt-16 py-8 lg:py-20" id="our_resources">
      <div className="cc-section--horizontal-space">
        <SectionTitle
          title={`Our <span>Resources</span>`}
          titleEC={`justify-center`}
          componentEC={`text-center`}
        />
        <div className="flex flex-wrap gap-4 mt-8 border border-[#1C5C4F] rounded-[6px] p-[2px] max-w-[612px] mx-auto">
          {uniqueCategories.map((category, index) => (
            <button
              key={index}
              type="button"
              onClick={() => setSelectedCategory(category)}
              className={`rounded-[6px] capitalize transition flex-[1] px-4 py-2 md:py-3 text-[16px] md:text-[18px] font-[400] leading-[1.2em] text-[#7F7F7F] ${
                selectedCategory === category ? "bg-primary text-secondary" : ""
              }`}
            >
              {category}
            </button>
          ))}
        </div>
        {paginatedPosts.length > 0 ? (
          <div>
            <div
              className="flex flex-wrap justify-start mt-10"
              style={{ rowGap: `15px` }}
            >
              {paginatedPosts.map((item, index) => (
                <div
                  key={`resource_card_${index}`}
                  className="w-full md:w-1/3 px-2 mt-2"
                >
                  <ResourceCard post={item} />
                </div>
              ))}
            </div>

            <div className="flex justify-center mt-16">
              <button
                type="button"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                  currentPage === 1 ? "text-[#979797]" : "text-[#000000]"
                } transition hover:bg-primary hover:text-primary`}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handlePageChange(index + 1)}
                  className={`mx-1 px-5 py-3 border border-[#BBBBBB] rounded ${
                    currentPage === index + 1
                      ? "bg-primary text-secondary"
                      : "text-[#000000]"
                  } transition hover:bg-primary hover:text-primary`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                type="button"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                  currentPage === totalPages
                    ? "text-[#979797]"
                    : "text-[#000000]"
                } transition hover:bg-primary hover:text-primary`}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <p className="text-center mt-16">
            No Resources match your search query.
          </p>
        )}
      </div>
    </div>
  )
}

export default OurResourcesSection
